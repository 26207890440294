<template>
<div id="verify">
  <div class="companyInfo">
    <h3>认证处理中</h3>
    <p>您的企业认证已经提交，我们将在一个工作日内尽快审核审核时间: 8:00-22:00</p>
    <div class="company-ct">
      <div class="info">
        <img src="@/assets/imgs/company/companyBtn.png" alt="">
        <span>营业执照</span> <label>审核中</label>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {  viewPersonalApprovalStatus } from "@/api/index.js";
export default {
  name: "verify",
  data() {
    return {

    }
  },
  mounted(){
    this.viewPersonalApprovalStatus()
  },
  methods: {
    viewPersonalApprovalStatus(){
        viewPersonalApprovalStatus().then(res=>{
          if(res.data.bindingStatus==2){
              this.$message({
                message: '认证成功',
                type: 'success'
              })
              this.$router.push({
                path: '/home'
              })
          }
          console.log(res)
        })
    }
  }
};
</script>

<style lang="scss" scoped>
#verify {
  width: 100%;
  min-height: 514px;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  padding-bottom: 30px;
  padding-top: 24px;
}

.companyInfo {
  width: 1065px;
  min-height: 353px;
  margin: 31px auto;
  background: #FFFFFF;
  padding-top: 60px;
  border-radius: 1px 10px 10px 10px;
  overflow: hidden;
  padding-bottom: 40px;

  h3 {
    font-size: 24px;
    font-weight: bold;
    color: #222222;
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    font-size: 16px;
    color: #222222;
    text-align: center;
    margin-bottom: 30px;
  }

  .company-ct {
    width: 730px;
    height: 154px;
    background: #F5F5F5;
    border-radius: 5px;
    margin: 0 auto;
    padding: 15px 20px;

    .info {
      width: 732px;
      height: 140px;
      background: #FFFFFF;
      border-radius: 5px;

      img {
        width: 86px;
        height: 86px;
        margin: 29px 40px;
        border-radius: 50%;
        float: left;
      }

      span {
        display: block;
        float: left;
        margin-top: 60px;
        font-size: 16px;
        color: #222222;
      }
       label {
          display: block;
          float: right;
          color: #0077FF;
          display: block;
           margin-top: 60px;
           margin-right: 40px;
          float: right;
        }
    }
  }
}
</style>
